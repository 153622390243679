<template>
    <mercur-card class="mx-4 mb-3">
        <h2 class="font-weight-normal">{{$parent.supplierName}}</h2>
    </mercur-card>
</template>

<script>
export default {
    name: 'SupplierDetailView',
}
</script>
